import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import { CSSTransitionGroup } from 'react-transition-group';
import Avatar from '../avatar';
import MoreButton from '../more-button';
import { PinIcon } from '../icons/pin-icon';
import { LockIcon } from '../icons/lock-icon';
import PostActions from '../../containers/post-actions';
import TimeAgo from '../time-ago';
import TopCommentIconLink from '../top-comment-icon-link';
import styles from './post-header.scss';
import withTranslate from '../../hoc/with-translate';
import { BulletSeparator } from '../separator';

const TIME_ANIMATION_TIMEOUT = 1000;
const ICON_ANIMATION_TIMEOUT = 200;

const renderPinIcon = (t, isPinned) => {
  if (!isPinned) {
    return null;
  }

  return (
    <div className={styles.iconWrapper} key="pin">
      <div className={classNames(styles.icon, 'pin-icon')} data-hook="pin-icon">
        <PinIcon
          className="icon-secondary-fill"
          isPinned={isPinned}
          aria-label={t('post-list-item.pinned-post')}
        />
      </div>
    </div>
  );
};

const renderLockIcon = (isCommentsDisabled, type) => {
  if (!isCommentsDisabled || type === PostHeader.POST_PAGE) {
    return null;
  }

  return (
    <div className={styles.iconWrapper} key="lock">
      <div className={classNames(styles.icon, 'lock-icon')} data-hook="lock-icon">
        <LockIcon className="icon-secondary-fill" isLocked={isCommentsDisabled} />
      </div>
    </div>
  );
};

const renderTopCommentIcon = (t, topCommentId) => {
  return (
    <div className={styles.iconWrapper} key="top-comment">
      <div className={styles.icon} data-hook="top-comment-icon">
        <TopCommentIconLink t={t} topCommentId={topCommentId} />
      </div>
    </div>
  );
};

const renderMoreButton = (moreButtonId, post, origin) => {
  return (
    <div className={styles.moreButton}>
      <MoreButton id={moreButtonId}>
        <PostActions post={post} focusOnCloseId={moreButtonId} origin={origin} />
      </MoreButton>
    </div>
  );
};

const avatarTypeMap = {
  single: Avatar.LARGE,
  search: Avatar.MEDIUM,
  medium: Avatar.MEDIUM,
  large: Avatar.LARGE,
  footerPost: Avatar.FOOTER_POST,
  postPage: Avatar.POST_PAGE,
  recentPostsWidget: Avatar.RECENT_POSTS_WIDGET,
};

function PostHeader({
  type,
  post,
  isAnimated,
  showTopComment,
  showLastActivityDate,
  showDateBelowAvatar,
  showAvatarBadges,
  showStrippedBadges,
  showPinIcon,
  showLockIcon,
  showMoreButton,
  showEditedDate,
  showMemberCard,
  truncateBadgeTitle,
  t,
  isMobilePostHeader,
  isQACategoriesEnabled,
  origin,
}) {
  const owner = post.owner || {};
  const className = classNames(
    styles.container,
    styles[type],
    'post-header',
    isQACategoriesEnabled && styles.QACategories,
  );
  const avatarType = avatarTypeMap[type];
  const moreButtonId = `more-button-${post._id}`;
  const shouldShowTopComment = isQACategoriesEnabled
    ? showTopComment && Boolean(post.bestAnswerCommentId)
    : showTopComment && Boolean(post.topCommentId);
  const commentId = isQACategoriesEnabled ? post.bestAnswerCommentId : post.topCommentId;
  const date = showLastActivityDate ? post.lastActivityDate : post.createdDate;

  return (
    <div className={className}>
      <Avatar
        type={avatarType}
        user={owner}
        date={date}
        showDate={!isMobilePostHeader && showDateBelowAvatar}
        showBadges={showAvatarBadges}
        showStrippedBadges={showStrippedBadges}
        truncateBadgeTitle={truncateBadgeTitle}
        showMemberCard={showMemberCard}
      />
      <div className={styles.status}>
        <div className={styles.date}>
          {(!isQACategoriesEnabled || type === PostHeader.POST_PAGE) && (
            <div key="timeago">
              {!isMobilePostHeader && !showDateBelowAvatar && <TimeAgo time={date} />}
            </div>
          )}
          {!isMobilePostHeader && showEditedDate && post.editedDate && (
            <span>
              <BulletSeparator />
              <span>{t('post-page.edited')}&nbsp;</span>
              <TimeAgo time={post.editedDate} />
            </span>
          )}
        </div>
        <CSSTransitionGroup
          className={styles.icons}
          transitionName="post-header-icons"
          transitionEnterTimeout={ICON_ANIMATION_TIMEOUT}
          transitionLeaveTimeout={ICON_ANIMATION_TIMEOUT}
        >
          {showPinIcon && renderPinIcon(t, post.isPinned)}
          {showLockIcon && !isMobilePostHeader && renderLockIcon(post.isCommentsDisabled, type)}
          {shouldShowTopComment && renderTopCommentIcon(t, commentId)}
        </CSSTransitionGroup>
        {showMoreButton && renderMoreButton(moreButtonId, post, origin)}
      </div>
    </div>
  );
}

PostHeader.SINGLE = 'single';
PostHeader.SEARCH = 'search';
PostHeader.SMALL = 'small';
PostHeader.MEDIUM = 'medium';
PostHeader.LARGE = 'large';
PostHeader.MEMBERS_AREA = 'membersArea';
PostHeader.FOOTER_POST = 'footerPost';
PostHeader.POST_PAGE = 'postPage';
PostHeader.RECENT_POSTS_WIDGET = 'recentPostsWidget';

PostHeader.propTypes = {
  type: PropTypes.string,
  isAnimated: PropTypes.bool,
  post: PropTypes.object.isRequired,
  showPinIcon: PropTypes.bool,
  showLockIcon: PropTypes.bool,
  showTopComment: PropTypes.bool,
  showLastActivityDate: PropTypes.bool,
  showDateBelowAvatar: PropTypes.bool,
  showAvatarBadges: PropTypes.bool,
  showStrippedBadges: PropTypes.bool,
  showMoreButton: PropTypes.bool,
  showEditedDate: PropTypes.bool,
  showMemberCard: PropTypes.bool,
  t: PropTypes.func,
  isMobilePostHeader: PropTypes.bool,
  origin: PropTypes.string.isRequired,
};

PostHeader.defaultProps = {
  showAvatarBadges: true,
  showPinIcon: true,
  showLockIcon: true,
  showMoreButton: true,
  showStrippedBadges: true,
  showEditedDate: false,
  showTopComment: false,
};

export default flowRight(withTranslate)(PostHeader);
