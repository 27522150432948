import { withSentryErrorBoundary } from 'native-components-infra/dist/es/src/HOC/sentryErrorBoundary/sentryErrorBoundary';
import { withStyles } from 'native-components-infra/dist/es/src/HOC/withStyles/withStyles';
import RecentPostsWidgetRoot from './components/recent-posts-widget-root';
import { withStateReceiver } from '../common/services/state-optimizer/with-state-receiver';
import { withLayoutUpdate } from '../common/hoc/with-layout-update';
import { withInvalidAppState } from '../common/hoc/with-invalid-app-state';
import { SENTRY_COMPONENT_SCRIPT_DSN } from '../common/constants/sentry-viewer-dsn';
import InvalidAppStatePage from '../app/components/invalid-app-state-page';

const isProduction = process.env.NODE_ENV === 'production';
const withStylesConfig = {
  ltrCssPath: isProduction
    ? 'recent-posts-widget-viewer.min.css'
    : 'recent-posts-widget-viewer.css',
  rtlCssPath: isProduction
    ? 'recent-posts-widget-viewer.rtl.min.css'
    : 'recent-posts-widget-viewer.rtl.css',
};

const FallbackPage = withStyles(InvalidAppStatePage, withStylesConfig);

export default {
  component: withSentryErrorBoundary(
    withLayoutUpdate(
      withInvalidAppState(withStateReceiver(withStyles(RecentPostsWidgetRoot, withStylesConfig))),
    ),
    {
      dsn: SENTRY_COMPONENT_SCRIPT_DSN,
      config: {
        environment: `ooi-recent-posts-widget-${process.env.NODE_ENV}`,
      },
    },
    FallbackPage,
  ),
};
