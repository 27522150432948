export const EXPERIMENT_FILE_UPLOAD = 'specs.wixForum.FileUploadNew';
export const EXPERIMENT_RENDER_SEO_TAGS = 'specs.wixForum.RenderSeoTags';
export const EXPERIMENT_PRIVATE_CATEGORIES = 'specs.wixForum.PrivateCategories';
export const EXPERIMENT_MARK_AS_SOLVED = 'specs.wixForum.MarkAsSolved';
export const EXPERIMENT_POST_RATE_LIMITER = 'specs.wixForum.PostRateLimiter';
export const EXPERIMENT_USE_DUPLEXER = 'specs.wixForum.UseDuplexer';
export const EXPERIMENT_SET_SEO_STATUS_CODE = 'specs.wixForum.SetSeoStatusCode';
export const EXPERIMENT_PAID_PLANS = 'specs.wixForum.PaidPlans';
export const EXPERIMENT_FORCE_USER_OPEN_SETTINGS = 'specs.wixForum.ForceUserOpenSettings';
export const EXPERIMENT_QA_CATEGORIES = 'specs.wixForum.QACategories';
export const EXPERIMENT_WRITE_COMMENT_BEFORE_LOGIN = 'specs.wixForum.WriteCommentBeforeLogin';
export const EXPERIMENT_VIDEO_FILE_UPLOAD = 'specs.wixForum.VideoFileUpload';
export const EXPERIMENT_RELATED_POSTS = 'specs.wixForum.RelatedPosts';
export const EXPERIMENT_IMAGE_EXPAND = 'specs.wixForum.ImageExpand';
export const EXPERIMENT_INVISIBLE_CATS = 'specs.wixForum.InvisibleCats';
